import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, TextInput, SelectInput } from 'react-admin'
import CountryInput from 'src/components/inputs/CountryInput'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import RegionInput from '../../components/inputs/ReqionInput'
import { ServiceTypeList } from '../../types/enum/ServiceType'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source={'name:$contL'} label={'Название'} />
    <TextInput source={'filterName:$contL'} label={'Название в фильтре'} />
    <CountryInput source={'countryId'} label={'Страна'} />
    <RegionInput source={'regionId'} label={'Регион'} />
    <SelectInput source={'serviceType'} label={'Тип'} choices={ServiceTypeList} />
  </Filter>
)

const FeatureList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Категории для ресторанов"
      empty={
        <EmptyList
          title={'Нет категорий для ресторанов'}
          description={'Вы можете добавить категорию'}
          buttonText={'Добавить категорию'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Название'} />
        <TextField source="filterName" label={'Название в фильтре'} />
        <TextField source="region.name" label={'Регион'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default FeatureList
