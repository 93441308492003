import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const PartnerLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) =>
        `/partner/${get(record, props.source && props.source.endsWith('id') ? props.source : 'partner.id')}`
      }
      source={props.source ?? 'partner.id'}
      value={(record) => (props.mainSource ? get(record, props.mainSource ?? 'partner')?.name : record?.partner?.name)}
      label={props.label}
    />
  )
}

export default PartnerLinkField
