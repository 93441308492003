import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { FeatureForm } from './FeatureForm'

const FeatureEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <FeatureForm />
    </Edit>
  )
}
export default FeatureEdit
