import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField, TextInput, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { FeatureTemplateTypeList } from '../../types/enum/FeatureTemplateType'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import FeatureInput from '../../components/inputs/FeatureInput'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source={'key:$contL'} label={'ID поля'} />
    <TextInput source={'name:$contL'} label={'Название поля'} />
    <TextInput source={'feature.name:$contL'} label={'Название категории'} />
    <FeatureInput source={'featureId'} label={'Категория'} />
    <SelectInput source={'type'} label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
    <CountryInput source={'feature.countryId'} label={'Страна'} />
    <RegionInput source={'feature.regionId'} label={'Регион'} />
  </Filter>
)

const FeatureTemplateList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Дополнительные поля для категорий"
      empty={
        <EmptyList
          title={'Нет дополнительных полей для категорий'}
          description={'Вы можете добавить дополнтиельное поле'}
          buttonText={'Добавить дополнительное поле'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="key" label={'ID поля'} />
        <TextField source="name" label={'Название поля'} />
        <TextField source="feature.name" label={'Категория'} />
        <TextField source="feature.region.name" label={'Регион'} />
        <SelectField source="type" label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default FeatureTemplateList
