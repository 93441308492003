import * as React from 'react'
import { SimpleForm, NumberInput, TextInput, required, RadioButtonGroupInput, FormDataConsumer } from 'react-admin'
import { PromoKind } from 'src/types/enum/PromoKind'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import PromoUnitInput from 'src/components/inputs/PromoUnitInput'
import PromoUnitGroupInput from 'src/components/inputs/PromoUnitGroupInput'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { useMemo, useState } from 'react'
import CountryInput from '../../components/inputs/CountryInput'
import { useFormContext } from 'react-hook-form'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

enum PromoCodeType {
  Global = 'Global',
  PromoUnit = 'PromoUnit',
  PromoUnitGroup = 'PromoUnitGroup',
}

export const PromoCodeTypeList = [
  { id: PromoCodeType.Global, name: 'Глобальный' },
  { id: PromoCodeType.PromoUnit, name: 'Акция заведения' },
  { id: PromoCodeType.PromoUnitGroup, name: 'Группы акций заведения' },
]

enum PromoCodePurpose {
  Apply = 'Apply',
  Supplement = 'Supplement',
}

export const PromoCodePurposeList = [
  { id: PromoCodePurpose.Apply, name: 'Основная акция' },
  { id: PromoCodePurpose.Supplement, name: 'Дополнительная акция' },
]

type Props = {
  promoCodeType: PromoCodeType
}

const PromoInputs = ({ promoCodeType }: Props) => {
  const form = useFormContext()
  //
  const [countryId, setCountryId] = useState<string | null>(null)
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])

  return (
    <>
      {promoCodeType === PromoCodeType.Global && (
        <>
          <CountryInput
            source={'countryId'}
            label={'Страна'}
            fullWidth
            allowEmpty={false}
            onChange={(val) => {
              form.setValue('promoId', null)
              setCountryId(val)
            }}
            validate={required()}
          />
          <PromoServiceDeliveryInput
            resettable={true}
            source="promoId"
            filter={{ kind: PromoKind.Personal, ...getCountryIdFilterBasedOnProject(countryId) }}
            isTakeaway={isTakeaway}
            fullWidth
          />
        </>
      )}
      {promoCodeType === PromoCodeType.PromoUnit && (
        <PromoUnitInput
          resettable
          source="promoUnitId"
          filter={{ 'promo.kind': PromoKind.Personal }}
          isTakeaway={isTakeaway}
          fullWidth
        />
      )}
      {promoCodeType === PromoCodeType.PromoUnitGroup && (
        <PromoUnitGroupInput
          resettable
          source="promoUnitGroupId"
          filter={{ 'promo.kind': PromoKind.Personal }}
          isTakeaway={isTakeaway}
          fullWidth
        />
      )}
    </>
  )
}

const PromoCodeForm = (props: any) => {
  const record = useRecordContext()

  return (
    <SimpleForm {...props} redirect={'list'}>
      {props.isCreation ? (
        <RadioButtonGroupInput
          source="promoCodeType"
          label={'Тип промокода'}
          choices={PromoCodeTypeList}
          defaultValue={PromoCodeType.Global}
          row={false}
          validate={[required()]}
        />
      ) : (
        <Typography variant={'overline'} mb={1}>
          Тип промокода: {PromoCodeTypeList.find((el) => el.id === record.promoCodeType)?.name}
        </Typography>
      )}
      {props.isCreation ? (
        <RadioButtonGroupInput
          source="promoCodePurpose"
          label={'Предназначение промокода'}
          choices={PromoCodePurposeList}
          defaultValue={PromoCodePurpose.Apply}
          row={false}
          validate={[required()]}
        />
      ) : (
        <Typography variant={'overline'} mb={1}>
          Предназначение промокода: {PromoCodePurposeList.find((el) => el.id === record.promoCodePurpose)?.name}
        </Typography>
      )}
      <TextInput source="code" label={'Промокод'} fullWidth validate={[required()]} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => <PromoInputs promoCodeType={formData.promoCodeType} />}
      </FormDataConsumer>
      <NumberInput source="perCustomerUsageLimit" label={'Лимит для одного клиента'} helperText={''} fullWidth />
      <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
    </SimpleForm>
  )
}
export default PromoCodeForm
