import * as React from 'react'
import { SimpleForm, SelectInput, TextInput, BooleanInput, required, ArrayInput, SimpleFormIterator } from 'react-admin'
import { ViewTemplateType, ViewTemplateTypeList } from 'src/types/enum/ViewTemplateType'
import { ViewTemplateItemList } from 'src/resources/view-template/view-template-item/ViewTemplateItemList'
import { ServiceTypeList } from 'src/types/enum/ServiceType'
import CountryInput from '../../components/inputs/CountryInput'
import { isDandbProject } from '../../components/list/tree/utils'
import LanguageInput from '../../components/inputs/LanguageInput'

const Form = () => {
  return (
    <>
      {isDandbProject() ? (
        <ArrayInput source="translations" label={'Название'}>
          <SimpleFormIterator inline={false}>
            <LanguageInput
              source="languageCode"
              label={'Язык'}
              validate={required()}
              helperText={false}
              fullWidth={false}
            />
            <TextInput source="name" label={'Название'} validate={[required()]} fullWidth={false} />
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        <TextInput source="name" label={'Название'} validate={[required()]} fullWidth />
      )}
      <CountryInput source="countryId" label={'Страна'} validate={[required()]} fullWidth />
      <SelectInput source="serviceType" label={'Сервис'} choices={ServiceTypeList} />
      <SelectInput source={'type'} label={'Тип'} choices={ViewTemplateTypeList} />
      <BooleanInput source={'isDefault'} label={'По умолчанию'} />
    </>
  )
}
const ViewTemplateForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ type: ViewTemplateType.Main }}>
      <Form />
      {props.isEdit && <ViewTemplateItemList />}
    </SimpleForm>
  )
}
export default ViewTemplateForm
