import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { FeatureForm } from './FeatureForm'

interface TitleProps {
  record?: any
}

const Title: FC<TitleProps> = ({ record }) => <span>Новая категория</span>

const FeatureCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <FeatureForm />
    </Create>
  )
}

export default FeatureCreate
