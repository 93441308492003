import * as React from 'react'
import { FC, useMemo } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField, TextInput, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { FeatureTemplateType, FeatureTemplateTypeList } from '../../types/enum/FeatureTemplateType'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import FeatureInput from '../../components/inputs/FeatureInput'
import RestaurantLinkField from '../../components/fields/RestaurantLinkField'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import moment from 'moment'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source={'key:$contL'} label={'ID поля'} />
    <TextInput source={'name:$contL'} label={'Название поля'} />
    <TextInput source={'feature.name:$contL'} label={'Название категории'} />
    <FeatureInput source={'featureId'} label={'Категория'} />
    <SelectInput source={'type'} label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
    <CountryInput source={'feature.countryId'} label={'Страна'} />
    <RegionInput source={'feature.regionId'} label={'Регион'} />
  </Filter>
)

const ValueField = (props: any) => {
  const record = useRecordContext()

  const value = useMemo(() => {
    switch (record.template.type as FeatureTemplateType) {
      case FeatureTemplateType.BOOLEAN:
        return record.value == 'true' ? 'Да' : 'Нет'
      case FeatureTemplateType.LESS_THAN_NUMBER:
        return `< ${record.value}`
      case FeatureTemplateType.GREATER_THAN_NUMBER:
        return `> ${record.value}`
      case FeatureTemplateType.CURRENCY:
        return `${record.value} ${record.restaurant?.country?.currency || 'RUB'}`
      case FeatureTemplateType.LESS_THAN_CURRENCY:
        return `< ${record.value} ${record.restaurant?.country?.currency || 'RUB'}`
      case FeatureTemplateType.GREATER_THAN_CURRENCY:
        return `> ${record.value} ${record.restaurant?.country?.currency || 'RUB'}`
      case FeatureTemplateType.DATE:
        return moment(record.value).format('DD.MM.YYYY HH:mm')
      case FeatureTemplateType.LESS_THAN_DATE:
        return `< ${moment(record.value).format('DD.MM.YYYY HH:mm')}`
      case FeatureTemplateType.GREATER_THAN_DATE:
        return `> ${moment(record.value).format('DD.MM.YYYY HH:mm')}`
      case FeatureTemplateType.NUMBER:
      case FeatureTemplateType.STRING:
      default:
        return record.value
    }
  }, [record.template?.type])

  return <Typography>{value}</Typography>
}

const RestaurantFeatureList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Категории ресторанов"
      empty={
        <EmptyList
          title={'Нет категорий ресторанов'}
          description={'Вы можете добавить категорию ресторана'}
          buttonText={'Добавить категорию'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="template.key" label={'ID поля'} />
        <TextField source="template.name" label={'Название поля'} />
        <TextField source="template.feature.name" label={'Категория'} />
        <RestaurantLinkField mainSource="restaurant" label={'Ресторан'} />
        <SelectField source="template.type" label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
        <ValueField label={'Содержимое'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default RestaurantFeatureList
