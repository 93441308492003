import FeatureTemplateList from './FeatureTemplateList'
import FeatureTemplateCreate from './FeatureTemplateCreate'
import PersonIcon from '@mui/icons-material/Person'
import FeatureTemplateEdit from './FeatureTemplateEdit'

export default {
  create: FeatureTemplateCreate,
  edit: FeatureTemplateEdit,
  list: FeatureTemplateList,
  icon: PersonIcon,
}
