import * as React from 'react'
import { TabbedForm, FormTab, TextInput, required, BooleanInput, ArrayInput, SimpleFormIterator } from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import Formatter from 'src/utils/formatters'
import CountryInput from '../../components/inputs/CountryInput'
import { isDandbProject } from '../../components/list/tree/utils'
import LanguageInput from '../../components/inputs/LanguageInput'

const InfoTab = (props: any) => {
  const form = useFormContext()

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }

  return (
    <FormTab label="Товарный раздел" path={'benefit'} {...props}>
      {isDandbProject() ? (
        <ArrayInput source="translations" label={'Название'}>
          <SimpleFormIterator inline={false}>
            <LanguageInput
              source="languageCode"
              label={'Язык'}
              validate={required()}
              helperText={false}
              fullWidth={false}
            />
            <TextInput
              source="name"
              label={'Название'}
              validate={[required()]}
              fullWidth={false}
              onChange={handleNameChange}
            />
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
      )}
      <CountryInput source="countryId" label={'Страна'} validate={[required()]} fullWidth />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <BooleanInput source="published" label={'Активен'} fullWidth />
    </FormTab>
  )
}
const CategoryForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'} defaultValues={{ published: true }}>
      <InfoTab />
    </TabbedForm>
  )
}
export default CategoryForm
