import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { FeatureTemplateForm } from './FeatureTemplateForm'

const FeatureTemplateEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <FeatureTemplateForm />
    </Edit>
  )
}
export default FeatureTemplateEdit
