import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, Datagrid, TextField, DateField, SearchInput } from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import TransTextField from 'src/components/fields/TransTextField'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Country',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <SearchInput
        source="translations.name:$contL&code:$contL"
        variant={'outlined'}
        alwaysOn={true}
        resettable={true}
      />
    </Filter>
  )
}

const CountryList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Страны"
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <VisibilityField source={'published'} label={'Опубликовано'} />
        <TransTextField source="name" label={'Название'} sortable={false} />
        <TextField source="slug" label={'uri'} />
        <TextField source="code" label={'Код'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default CountryList
