import FeatureList from './FeatureList'
import FeatureCreate from './FeatureCreate'
import PersonIcon from '@mui/icons-material/Person'
import FeatureEdit from './FeatureEdit'

export default {
  create: FeatureCreate,
  edit: FeatureEdit,
  list: FeatureList,
  icon: PersonIcon,
}
