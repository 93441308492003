import * as React from 'react'
import { FieldProps } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import { Typography } from '@mui/material'
import get from 'lodash/get'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { useRecordContext } from 'ra-core'
import { ServiceType } from 'src/types/enum/ServiceType'
import Formatter from 'src/utils/formatters'

interface Props extends FieldProps {
  mainSource?: string
  sourcePhone?: string
  withPhone?: boolean
}

const RestaurantLinkField = (props: Props) => {
  const record = useRecordContext(props)
  const restaurant = get(record, props.mainSource ?? 'restaurant')
  const entityName =
    restaurant.serviceType && restaurant.serviceType === ServiceType.Coffee ? 'restaurant-coffee' : 'restaurant'
  const phoneNumber = restaurant?.contactsPhone || restaurant?.phone

  return (
    <>
      {props.withPhone && phoneNumber && (
        <Typography>
          {Formatter.formatPhone(phoneNumber, record.country?.code || record.restaurant?.country?.code)}
        </Typography>
      )}
      <LinkField
        link={(record) =>
          `/${entityName}/${get(record, props.source && props.source.endsWith('id') ? props.source : 'restaurant.id')}`
        }
        source={props.source ?? 'restaurant.id'}
        value={(record) =>
          props.mainSource ? get(record, props.mainSource ?? 'restaurant')?.name : record?.restaurant?.name
        }
        label={props.label}
        icon={
          props.withPhone ? (
            <PhoneCallActionField
              source={props.sourcePhone ?? `${props.mainSource ?? 'restaurant'}.phone`}
              phone={phoneNumber}
              onlyIcon={true}
            />
          ) : undefined
        }
      />
    </>
  )
}

export default RestaurantLinkField
