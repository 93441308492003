import React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import { useRecordContext } from 'ra-core'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { isDandbProject } from '../list/tree/utils'

interface Props<RecordType extends RaRecord = any> extends FieldProps {}

const CountryField = (props: Props) => {
  const record = useRecordContext(props)
  const name = get(record, props.source ?? 'name')

  if (!isDandbProject()) {
    return <></>
  }
  return (
    <Box>
      <Typography variant={'body1'}>{name}</Typography>
    </Box>
  )
}

export default CountryField
