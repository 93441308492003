import * as React from 'react'
import { BooleanInput, SimpleForm, SimpleFormIterator, required, TextInput, ArrayInput } from 'react-admin'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import LanguageInput from 'src/components/inputs/LanguageInput'
import CountryInput from '../../components/inputs/CountryInput'

export const PageForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ translations: [{ name: null, languageCode: 'ru' }] }}>
      <BooleanInput source={'published'} label={'Опубликовано'} />
      <TextInput source={'key'} label={'Ключ'} validate={required()} variant={'outlined'} />
      <CountryInput source="countryId" label={'Страна'} validate={[required()]} fullWidth />

      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <LanguageInput source="languageCode" label={'Язык'} validate={required()} />
          <TextInput source={'name'} label={'Название'} validate={required()} fullWidth={true} variant={'outlined'} />
          <RichTextInput
            source="body"
            label={'Содержимое'}
            fullWidth={true}
            variant={'outlined'}
            validate={required()}
          />
          <TextInput source={'seoTitle'} label={'SEO title'} fullWidth={true} variant={'outlined'} />
          <TextInput source={'seoDescription'} label={'SEO Description'} fullWidth={true} variant={'outlined'} />
          <TextInput source={'seoKeywords'} label={'SEO Keywords'} fullWidth={true} variant={'outlined'} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}
