import * as React from 'react'
import {
  TextInput,
  FormTab,
  SelectInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin'
import * as _ from 'lodash'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { PromoTemplate, PromoTemplateList } from 'src/types/enum/PromoTemplate'
import { PromoColor, PromoColorList, PromoTextColorList } from 'src/types/enum/PromoColor'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PromoColorImageCard from 'src/components/Promo/PromoColorImageCard'
import PromoGradientImageCard from 'src/components/Promo/PromoGradientImageCard'
import { isDandbProject } from '../../list/tree/utils'
import LanguageInput, { LanguageTypes } from '../../inputs/LanguageInput'
import { useFormContext } from 'react-hook-form'
import PromoColorImageBigCard from '../PromoColorImageBigCard'
import { useMemo } from 'react'

const PromoFormViewTab = (props: any) => {
  const form = useFormContext()
  const formValues = form.watch()
  const translationsLanguages = _.isArray(formValues.translations)
    ? formValues.translations.map((tr) => tr.languageCode).join(', ')
    : ''

  const translation = useMemo(() => {
    if (!isDandbProject()) {
      return form.getValues()
    }
    if (!_.isArray(formValues.translations) || _.isEmpty(formValues.translations)) {
      return null
    }
    return (
      formValues.translations.find((translation) => translation.languageCode === LanguageTypes.RU) ||
      formValues.translations.find((translation) => translation.languageCode === LanguageTypes.EN) ||
      formValues.translations[0]
    )
  }, [formValues.translations?.length, translationsLanguages])

  return (
    <FormTab label="Вид/Описание" path={'benefit'} {...props}>
      <Grid container>
        <Grid item xs={6}>
          {isDandbProject() && (
            <ArrayInput source="translations" label={'Переводы'}>
              <SimpleFormIterator inline={false}>
                <LanguageInput
                  source="languageCode"
                  label={'Язык'}
                  validate={required()}
                  helperText={false}
                  fullWidth={false}
                />
                <TextInput
                  source="name"
                  label={'Название'}
                  validate={[required()]}
                  helperText={false}
                  fullWidth={false}
                />
                {formValues.template === PromoTemplate.WithImageGradient && (
                  <TextInput
                    source="bigText"
                    label={'Большой текст'}
                    validate={[required()]}
                    helperText={false}
                    fullWidth={false}
                  />
                )}
                {formValues.template === PromoTemplate.WithImageGradient && (
                  <TextInput
                    source="badge"
                    label={'Подпись'}
                    validate={required()}
                    helperText={false}
                    fullWidth={false}
                  />
                )}
                {formValues.template === PromoTemplate.WithImage && (
                  <TextInput
                    source="badge"
                    label={'Текст на бейдже'}
                    validate={required()}
                    helperText={false}
                    fullWidth={false}
                  />
                )}
                {formValues.template === PromoTemplate.WithImageGradient && (
                  <TextInput source="shortDesc" label={'Короткое описание'} helperText={false} fullWidth={false} />
                )}
                <RichTextInput source="description" label={'Описание'} helperText={false} fullWidth={false} />
              </SimpleFormIterator>
            </ArrayInput>
          )}
          {!isDandbProject() && <TextInput source="name" label={'Название'} validate={[required()]} fullWidth />}
          <SelectInput
            source="template"
            label={'Шаблон баннера'}
            choices={PromoTemplateList}
            validate={[required()]}
            fullWidth
          />
          <SelectInput
            source="color"
            label={'Цвет'}
            choices={PromoColorList}
            validate={[required()]}
            fullWidth
            optionText={(i) => (
              <div
                style={{
                  ...(i.id
                    ? {
                        width: '50%',
                        borderRadius: '4px',
                        background: i.id.includes(';')
                          ? `linear-gradient(125.99deg, ${i.id.split(';')[0]} -2.47%, ${i.id.split(';')[1]} 86.22%)`
                          : i.id,
                      }
                    : {}),
                }}
              >
                &nbsp;
              </div>
            )}
          />

          {!isDandbProject() && formValues.template === PromoTemplate.WithImageGradient && (
            <>
              <TextInput source="bigText" label={'Большой текст'} validate={required()} fullWidth />
              <TextInput source="badge" label={'Подпись'} fullWidth />
              <TextInput source="shortDesc" label={'Короткое описание текст'} fullWidth />
            </>
          )}

          {formValues.template === PromoTemplate.WithImage && (
            <>
              <SelectInput
                source="textColor"
                label={'Цвет текста'}
                choices={PromoTextColorList}
                validate={[required()]}
                fullWidth
              />
              {!isDandbProject() && <TextInput source="badge" label={'Текст на бейдже'} fullWidth />}
              <SelectInput source="badgeColor" label={'Цвет бейджа'} choices={PromoTextColorList} fullWidth />
            </>
          )}

          <MediaInput source="image" label={'Картинка'} fullWidth helperText={'Прозрачная png без фона'} />

          {!isDandbProject() && <RichTextInput source="description" label={'Описание'} fullWidth />}

          <MediaInput
            source="imageLarge"
            label={'Картинка болшая'}
            helperText={
              'Необязательно если не указывать то будет использоваться фоновый цвет или фон в зависимости от шаблона'
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: '0px 15px' }}>
          <Typography variant="h6" paragraph>
            Вид карточки акции
          </Typography>

          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) => {
              const isVsemEdaProject = !isDandbProject()

              switch (formData.template) {
                case PromoTemplate.WithImage:
                  return (
                    <PromoColorImageCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={isVsemEdaProject ? formValues.name : translation?.name}
                      badge={isVsemEdaProject ? formValues.badge : translation?.badge}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                    />
                  )

                case PromoTemplate.WithImageGradient:
                  return (
                    <PromoGradientImageCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={isVsemEdaProject ? formValues.name : translation?.name}
                      badge={isVsemEdaProject ? formValues.badge : translation?.badge}
                      bigText={isVsemEdaProject ? formValues.bigText : translation?.bigText}
                      shortDesc={isVsemEdaProject ? formValues.shortDesc : translation?.shortDesc}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      text={isVsemEdaProject ? formValues.description : translation?.description}
                    />
                  )
              }
            }}
          </FormDataConsumer>

          <Typography variant="h6" mt={2}>
            Вид страница акции
          </Typography>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) => {
              const isVsemEdaProject = !isDandbProject()

              switch (formData.template) {
                case PromoTemplate.WithImage:
                  return (
                    <PromoColorImageBigCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={isVsemEdaProject ? formValues.name : translation?.name}
                      badge={isVsemEdaProject ? formValues.badge : translation?.badge}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      imageLarge={formData.imageLarge}
                      text={isVsemEdaProject ? formValues.description : translation?.description}
                    />
                  )

                case PromoTemplate.WithImageGradient:
                  return (
                    <PromoColorImageBigCard
                      icon={formData.icon}
                      color={formData.color}
                      template={formData.template}
                      name={isVsemEdaProject ? formValues.name : translation?.name}
                      badge={isVsemEdaProject ? formValues.badge : translation?.badge}
                      bigText={isVsemEdaProject ? formValues.bigText : translation?.bigText}
                      shortDesc={isVsemEdaProject ? formValues.shortDesc : translation?.shortDesc}
                      badgeColor={formData.badgeColor as PromoColor}
                      image={formData.image}
                      imageLarge={formData.imageLarge}
                      text={isVsemEdaProject ? formValues.description : translation?.description}
                    />
                  )
              }
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </FormTab>
  )
}
export default PromoFormViewTab
