import { required, SelectInput } from 'react-admin'
import * as React from 'react'

interface Props {
  source: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  fullWidth?: boolean
  validate?: any
  helperText?: string | false
}

export enum LanguageTypes {
  RU = 'ru',
  EN = 'en',
  HY = 'hy',
  RO = 'ro',
  KK = 'kk',
}

export const LanguageTypesList = [
  { id: LanguageTypes.RU, name: 'Русский' },
  { id: LanguageTypes.EN, name: 'Английский' },
  { id: LanguageTypes.HY, name: 'Армянский' },
  { id: LanguageTypes.RO, name: 'Румынский' },
  { id: LanguageTypes.KK, name: 'Казахскй' },
]

const LanguageInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      source={props.source ?? 'languageCode'}
      label={'Язык'}
      variant={'outlined'}
      choices={LanguageTypesList}
      validate={props.validate ?? required()}
      helperText={props.helperText}
    />
  )
}

export default LanguageInput
