import { AutocompleteInput, FilterPayload, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit } from 'lodash'
import { ServiceTypeList } from '../../../types/enum/ServiceType'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  fullWidth?: boolean
  filter?: FilterPayload
  validate?: any
  onChange?: (val: any) => void
}

const FeatureTemplateInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      source={props.source ?? 'templateId'}
      reference="feature-template"
      variant={'outlined'}
      filter={props.filter}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL&feature.name:$contL': q })}
        optionText={(i) =>
          `ID: ${i?.feature?.id} - ${ServiceTypeList.find((el) => el.id === i?.feature?.serviceType)?.name} - ${i?.feature?.name} - ${i?.name}${i?.feature?.region?.name ? ` - ${i.feature.region.name}` : ''}`
        }
        label="Поле для категории"
        fullWidth={props.fullWidth}
        onChange={props.onChange}
      />
    </ReferenceInput>
  )
}

export default FeatureTemplateInput
