import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  List,
  ListProps,
  SelectField,
  TextField,
  FunctionField,
  SelectInput,
  TextInput,
  useResourceContext,
} from 'react-admin'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import { PromoTypeList } from 'src/types/enum/PromoType'
import { PromoKindList } from 'src/types/enum/PromoKind'
import { DiscountTypeList } from 'src/types/enum/DiscountType'
import { PromoTemplateList } from 'src/types/enum/PromoTemplate'
import { PromoIconList } from 'src/types/enum/PromoIcon'
import { PromoStatusList } from 'src/types/enum/PromoStatus'
import { PromoColorList } from 'src/types/enum/PromoColor'
import VisibilityField from 'src/components/fields/VisibilityField'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import { PromoGroupList } from 'src/types/enum/PromoGroup'
import { Datagrid } from 'src/components/list/Datagrid'
import CountryField from '../../components/fields/CountryField'
import { isDandbProject } from '../../components/list/tree/utils'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,

      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Promo',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск...'} variant={'outlined'} alwaysOn={true} resettable={true} />
      <SelectInput source="template" label={'Шаблон баннера'} choices={PromoTemplateList} fullWidth />
      <SelectInput source="icon" label={'Вид акции'} choices={PromoIconList} fullWidth />
      <SelectInput source="status" label={'Статус'} choices={PromoStatusList} fullWidth />
      <SelectInput source="kind" label={'Вид'} choices={PromoKindList} fullWidth />
      <SelectInput source="type" label={'Тип'} choices={PromoTypeList} fullWidth />
      <SelectInput source="group" label={'Группа'} choices={PromoGroupList} fullWidth />
      <SelectInput source="color" label={'Цвет'} choices={PromoColorList} fullWidth />
      <SelectInput source="discountType" label={'Тип Скидки'} choices={DiscountTypeList} fullWidth />
    </Filter>
  )
}

const PromoList: FC<ListProps> = (props) => {
  const resource = useResourceContext()
  return (
    <List
      {...props}
      filters={<_Filter />}
      title={ServiceTypeDictionary.getPromo(resource)?.multi}
      sort={{ field: 'sort', order: 'ASC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <VisibilityField source={'published'} label={''} />
        <TextField source={'id'} label={'ID'} />
        <FunctionField source={'name'} label={'Название'} render={(order: any) => order.innerName ?? order.name} />
        <TextField source={'badge'} label={'Бейдж'} />
        <SelectField source={'kind'} label={'Вид'} choices={PromoKindList} />
        <SelectField source={'group'} label={'Группа'} choices={PromoGroupList} />
        {isDandbProject() && <CountryField source={'country.name'} label={'Страна'} sortable={false} />}
        <TextField source={'sort'} label={'Сортировка'} render={(order: any) => order.innerName ?? order.name} />
      </Datagrid>
    </List>
  )
}

export default PromoList
