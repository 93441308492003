import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { RestaurantFeatureForm } from './RestaurantFeatureForm'

const RestaurantFeatureEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <RestaurantFeatureForm isCreation={false} />
    </Edit>
  )
}
export default RestaurantFeatureEdit
