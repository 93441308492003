export enum FeatureTemplateType {
  NUMBER = 'number',
  LESS_THAN_NUMBER = 'less_than_number',
  GREATER_THAN_NUMBER = 'greater_than_number',
  DATE = 'date',
  LESS_THAN_DATE = 'less_than_date',
  GREATER_THAN_DATE = 'greater_than_date',
  STRING = 'string',
  BOOLEAN = 'boolean',
  CURRENCY = 'currency',
  LESS_THAN_CURRENCY = 'less_than_currency',
  GREATER_THAN_CURRENCY = 'greater_than_currency',
}

export const FeatureTemplateTypeList: Array<{ id: FeatureTemplateType; name: string }> = [
  { id: FeatureTemplateType.NUMBER, name: 'Число' },
  { id: FeatureTemplateType.LESS_THAN_NUMBER, name: 'Меньше числа N' },
  { id: FeatureTemplateType.GREATER_THAN_NUMBER, name: 'Больше числа N' },
  { id: FeatureTemplateType.DATE, name: 'Дата' },
  { id: FeatureTemplateType.LESS_THAN_DATE, name: 'Меньше N даты' },
  { id: FeatureTemplateType.GREATER_THAN_DATE, name: 'Больше N даты' },
  { id: FeatureTemplateType.STRING, name: 'Строка (текст)' },
  { id: FeatureTemplateType.BOOLEAN, name: 'Булевое значение Да/Нет' },
  { id: FeatureTemplateType.CURRENCY, name: 'Валюта' },
  { id: FeatureTemplateType.LESS_THAN_CURRENCY, name: 'Меньше числа (валюта) N' },
  { id: FeatureTemplateType.GREATER_THAN_CURRENCY, name: 'Больше числа (валюта) N' },
]
