import React, { ComponentProps, ReactElement, useRef } from 'react'
import { useInput, FieldTitle } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'
import { FormHelperText, FormControl, InputLabel } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { PartySuggestions } from 'react-dadata'
import { LegalType } from 'src/types/enum/LegalType'
import 'react-dadata/dist/react-dadata.css'
import { FieldError } from 'react-hook-form'

export interface IInnInputCompany {
  name: string
  inn: string
  kpp: string
  ogrn: string
  okpo: string
  address: string
  legalType: LegalType
}

export interface InnInputProps {
  label?: string | ReactElement
  source: string
  fullWidth?: boolean
  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  variant?: string
  onChange: (val: IInnInputCompany | null) => void
  [key: string]: any
}

const InnInput = (props: InnInputProps) => {
  const {
    options = {}, // Quill editor options
    fullWidth = true,
    configureQuill,
    helperText,
    label,
    source,
    resource,
    variant,
    ...rest
  } = props

  const {
    id,
    isRequired,
    field: { value, onChange },
    fieldState: { error, isTouched },
  } = useInput({ source, ...rest })

  const suggestionRef = useRef<PartySuggestions | null>(null)

  const handleChange = (val: any) => {
    if (!val) {
      props.onChange?.(null)
      return
    }
    const newCompany: IInnInputCompany = {
      name: val.value,
      inn: val.data.inn,
      kpp: val.data.kpp,
      ogrn: val.data.ogrn,
      okpo: val.data.okpo,
      address: val.data?.address?.value,
      legalType: val.data.type === 'LEGAL' ? LegalType.LegalEntity : LegalType.Ip,
    }
    onChange?.(newCompany.inn)
    props.onChange?.(newCompany)
    suggestionRef.current?.setInputValue(newCompany.inn!)
  }

  const getErrorMessage = (error?: FieldError) => {
    if (!error?.message) {
      return
    }
    const errorObj = JSON.parse(error.message.replace('@@react-admin@@', ''))
    //
    return errorObj?.message || errorObj
  }

  return (
    <div>
      <FormControl error={!!(isTouched && error)} className="ra-rich-text-input">
        <InputLabel shrink htmlFor={id}>
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        </InputLabel>

        <PartySuggestions
          ref={suggestionRef}
          defaultQuery={value ?? null}
          currentSuggestionClassName={styles.active}
          highlightClassName={styles.highlight}
          //  customInput={Input}
          inputProps={{
            onChange,
          }}
          onChange={handleChange}
          token={'61f1a1b1b139912dfa1a1c0bb1851abcb18f6f45'}
        />

        <FormHelperText error={!!error} className={!!error ? 'ra-rich-text-input-error' : ''}>
          <InputHelperText helperText={getErrorMessage(error)} touched={isTouched} />
        </FormHelperText>
      </FormControl>
    </div>
  )
}

InnInput.propTypes = {
  // @ts-ignore
  label: PropTypes.any,
  options: PropTypes.object,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func,
}

export default InnInput
