import * as React from 'react'
import { SimpleForm, required, TextInput, SelectInput } from 'react-admin'
import FeatureInput from '../../components/inputs/FeatureInput'
import { FeatureTemplateType, FeatureTemplateTypeList } from '../../types/enum/FeatureTemplateType'
import { useFormContext } from 'react-hook-form'
import CountryInput from 'src/components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import slugify from 'slugify'

const FeatureTemplateFields = () => {
  const form = useFormContext()
  const formValues = form.watch()

  return (
    <>
      <TextInput source={'name'} label={'Название поля'} validate={required()} />
      <TextInput
        source={'key'}
        label={'ID поля'}
        validate={required()}
        onChange={(event) => {
          const slugified = slugify(event.target.value)

          if (slugified !== formValues.key) {
            form.setValue('key', slugified)
          }
        }}
      />
      <CountryInput source={'countryId'} label={'Страна'} fullWidth />
      <FeatureInput
        source={'featureId'}
        label={'Категория'}
        filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
        fullWidth
        validate={required()}
      />
      <SelectInput source={'type'} label={'Тип содержимого'} choices={FeatureTemplateTypeList} validate={required()} />
    </>
  )
}

export const FeatureTemplateForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ type: FeatureTemplateType.STRING }}>
      <FeatureTemplateFields />
    </SimpleForm>
  )
}
