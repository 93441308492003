import * as React from 'react'
import { SimpleForm, required, TextInput, BooleanInput, SelectInput } from 'react-admin'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import { useFormContext } from 'react-hook-form'
import { ServiceTypeList } from '../../types/enum/ServiceType'

const CountryAndRegionFormFields = () => {
  const form = useFormContext()
  const formValues = form.watch()

  return (
    <>
      <CountryInput source={'countryId'} label={'Страна'} fullWidth />
      <RegionInput
        source={'regionId'}
        label={'Регион'}
        filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
        fullWidth
      />
    </>
  )
}

export const FeatureForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source={'name'} label={'Название'} validate={required()} />
      <TextInput source={'filterName'} label={'Название в фильтре'} validate={required()} />
      <SelectInput source={'serviceType'} label={'Тип'} choices={ServiceTypeList} validate={required()} />
      <CountryAndRegionFormFields />
      <BooleanInput source={'published'} label={'Видимость'} />
    </SimpleForm>
  )
}
