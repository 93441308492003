import RestaurantFeatureList from './RestaurantFeatureList'
import RestaurantFeatureCreate from './RestaurantFeatureCreate'
import PersonIcon from '@mui/icons-material/Person'
import RestaurantFeatureEdit from './RestaurantFeatureEdit'

export default {
  create: RestaurantFeatureCreate,
  edit: RestaurantFeatureEdit,
  list: RestaurantFeatureList,
  icon: PersonIcon,
}
